/**
 * 埋点事件
 */
export default {
    /**
     * 分类名称 - 模块名称
     */
    eventCategoryNames: {
        // 头部
        "header": "header",
        // 出错界面
        "error": "error",
        // 主页
        "home": "home",
        // 准备培训
        "prepare_training": "prepare_training",
        // 签到
        "sign_in": "sign_in",
        // 培训记录
        "training_record": "training_record",
        // 培训记录TraningRecordsTable
        "train_record_table": "train_record_table",
        // 上传材料
        "upload_materia": "upload_materia",
        // 视频播放videoTraining
        "video_training": "video_training",
    },

    /**
     * 事件行为名称 - 事件行为的位置
     */
    eventActionNames: {
        // 头部
        "header_btn_menu": "header_btn_menu",
        // 出错界面
        "error_btn_menu": "error_btn_menu",
        // 主页列表
        "home_list": "home_list",
        // 主页tab
        "home_tab": "home_tab",
        // 准备培训
        "prepare_training_menu": "prepare_training_menu",
        // 签到
        "sign_in_menu": "sign_in_menu",
        // 培训记录
        "training_record_menu": "training_record_menu",
        // 培训记录TraningRecordsTable
        "train_record_table_menu": "train_record_table_menu",
        // 上传材料
        "upload_materia_menu": "upload_materia_menu",
        // 视频播放videoTraining
        "video_training_menu": "video_training_menu",
    },
    /**
     * 事件名称
     */
    eventNameKeys: {
        // 头部推出现场学习
        "header_btn_menu_sign_out": "header_btn_menu_sign_out",

        // 主页HomeList
        // 主页HomeList的tab全部
        "home_tab_all": "home_tab_all",
        // 未开始
        "home_tab_not_started": "home_tab_not_started",
        // 进行中
        "home_tab_in_progress": "home_tab_in_progress",
        // 已完成
        "home_tab_completed": "home_tab_completed",
        // 主页列表 准备培训
        "home_list_preparation_train": "home_list_preparation_train",

        // 准备培训
        // 更换安全员
        "prepare_training_menu_change_safe_adminer": "prepare_training_menu_change_safe_adminer",
        // 更换考核人员
        "prepare_training_menu_change_auditor_adminer": "prepare_training_menu_change_auditor_adminer",
        // 更换授课人员
        "prepare_training_menu_changepr_lecturer_adminer": "prepare_training_menu_change_lecturer_adminer",

        // 更换培训管理人 lzb -2021-06-17 替换
        "prepare_training_menu_change": "prepare_training_menu_change",


        // 更换培训教师
        "prepare_training_menu_change_class_room": "prepare_training_menu_change_class_room",
        // 培训课程
        "prepare_training_menu_course_lis": "prepare_training_menu_course_lis",
        // 选中课程
        "prepare_training_menu_select_course_lis": "prepare_training_menu_select_course_lis",
        // 开始培训
        "prepare_training_menu_start_train": "prepare_training_menu_start_train",
        // 培训记录
        "prepare_training_menu_train_record": "prepare_training_menu_train_record",
        // 结束培训
        "prepare_training_menu_end_of_train": "prepare_training_menu_end_of_train",
        // 继续培训
        "prepare_training_menu_continuing_train": "prepare_training_menu_continuing_train",


        // 选择安全人员 -
        "prepare_training_menu_select_safe_personnel": "prepare_training_menu_select_safe_personnel",
        // 选择考核人员
        "prepare_training_menu_select_auditor_personnel": "prepare_training_menu_select_auditor_personnel",
        // 选择授课人员
        "prepare_training_menu_select_lecturer_personnel": "prepare_training_menu_select_lecturer_personnel",

        // 使用 V1.0.9 lzb 2021-06-17
        "prepare_training_menu_select": "prepare_training_menu_select",

        // 选择教室
        "prepare_training_menu_select_class_room": "prepare_training_menu_select_class_room",
        // dialog继续培训
        "prepare_training_menu_dialog_continue_train": "prepare_training_menu_dialog_continue_train",
        // dialog开始新的培训
        "prepare_training_menu_dialog_news_train": "prepare_training_menu_dialog_news_train",

        // 签到
        // 搜索学员
        "sign_in_menu_search_student": "sign_in_menu_search_student",
        // 签到
        "sign_in_menu_sign_in": "sign_in_menu_sign_in",
        // 签退
        "sign_in_menu_sign_out": "sign_in_menu_sign_out",
        // 抽验
        "sign_in_menu_spot_check": "sign_in_menu_spot_check",
        // 修改注册证
        "sign_in_menu_change_face": "sign_in_menu_change_face",
        // 关闭手机验证
        "sign_in_menu_close_phone": "sign_in_menu_close_phone",
        // 开启手机验证
        "sign_in_menu_open_phone": "sign_in_menu_open_phone",
        // 关闭电脑验证
        "sign_in_menu_close_pc": "sign_in_menu_close_pc",
        // 开启电脑验证
        "sign_in_menu_open_pc": "sign_in_menu_open_pc",
        // 退出上课验证
        "sign_in_menu_exit": "sign_in_menu_exit",
        // 拍照验证
        "sign_in_menu_take_photo": "sign_in_menu_take_photo",
        // 开始上课
        "sign_in_menu_begin_train": "sign_in_menu_begin_train",
        // 继续上课
        "sign_in_menu_continue_train": "sign_in_menu_continue_train",

        // 培训记录TrainingRecords
        // 准备培训
        "training_record_menu_preparation_train": "training_record_menu_preparation_train",
        // 查询
        "training_record_menu_table_query": "training_record_menu_table_query",


        // 培训记录TraningRecordsTable
        // 查询可签退
        "train_record_table_menu_query_sign_out": "train_record_table_menu_query_sign_out",
        // 查询培训记录
        "train_record_table_menu_query_train_record": "train_record_table_menu_query_train_record",
        // 查询操作记录
        "train_record_table_menu_query_operation_record": "train_record_table_menu_query_operation_record",
        // 可签退列表
        "train_record_table_menu_list_sign_out": "train_record_table_menu_list_sign_out",
        // 培训记录列表
        "train_record_table_menu_list_train_record": "train_record_table_menu_list_train_record",
        // 操作记录列表
        "train_record_table_menu_list_operation_record": "train_record_table_menu_list_operation_record",

        // 上传材料
        // 上传文件
        "upload_materia_menu_upload_file": "upload_materia_menu_upload_file",
        // 删除文件
        "upload_materia_menu_remove_file": "upload_materia_menu_remove_file",
        // 上传图片
        "upload_materia_menu_upload_image": "upload_materia_menu_upload_image",
        // 提交资料
        "upload_materia_menu_submit": "upload_materia_menu_submit",
        // 重置
        "upload_materia_menu_reset": "upload_materia_menu_reset",

        // 视频播放videoTraining
        // 收起
        "video_training_menu_put_menu_retract": "video_training_menu_put_menu_retract",
        // 展开
        "video_training_menu_put_menu_open": "video_training_menu_put_menu_open",
        // 电脑签到
        "video_training_menu_pc_check": "video_training_menu_pc_check",
        // 电脑抽验
        "video_training_menu_pc_sampling": "video_training_menu_pc_sampling",
        // 电脑签退
        "video_training_menu_pc_sign_out": "video_training_menu_pc_sign_out",
        // tab课程目录
        "video_training_menu_tab_course": "video_training_menu_tab_course",
        // tab签到
        "video_training_menu_tab_check": "video_training_menu_tab_check",
        // tab抽验
        "video_training_menu_tab_sampling": "video_training_menu_tab_sampling",
        // tab签退
        "video_training_menu_tab_sign_out": "video_training_menu_tab_sign_out",
        // 关闭签到二维码
        "video_training_menu_close_qrcode_sign": "video_training_menu_close_qrcode_sign",
        // 开启签到二维码
        "video_training_menu_open_qrcode_sign": "video_training_menu_open_qrcode_sign",
        // 您的网络已断开,点击重试
        "video_training_menu_connected_net": "video_training_menu_connected_net",
        // 跳转界面验证
        "video_training_menu_dialog_to_sign": "video_training_menu_dialog_to_sign",
        // 扫码验证或继续学习
        "video_training_menu_dialog_continue_to_sign": "video_training_menu_dialog_continue_to_sign",
    },

    // 默认事件值
    eventValues: {
        // 默认值
        defaultValue: 1,
    },


}
