var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
    _c("div", { attrs: { id: "app" } }, [
      _c(
        "div",
        { on: { mouseenter: _vm.onMouseEnter, mouseleave: _vm.onMouseLeave } },
        [
          _c("BrowerDown"),
          _c(
            "el-header",
            [_c("Header", { ref: "header", on: { logoutDo: _vm.logoutDo } })],
            1
          ),
          _c("router-view", {
            ref: "offline-train-box",
            on: {
              loginSuccess: _vm.monitorLoginSuccess,
              joinSystem: _vm.monitorJoinSystem
            }
          }),
          _c("Footer")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }