/**
 *  会议相关
 */
export const MEETING = {
    // 会议列表
    MEETING_LIST: "/meeting/list",
    // 会议准备
    MEETING_PREPARE: "/meeting/prepare",
};

/**
 *  培训相关
 */
export const TRAIN = {
    //培训列表
    TRAIN_LIST: "/train/list",
    // 培训准备
    TRAIN_PREPARE: "/train/prepare",
};
/**
 * 通用路由
 */
export const COMMON = {
    // 签到、抽验、签退
    VERIFY_PHOTO: "/verify/photo",
    // 培训内容
    TRAIN_VIDEO_STUDY: "/training",
    // 培训记录
    TRAIN_RECORD_LIST: "/train/record/list",
};
