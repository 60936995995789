import store from "../../store/store";

/**
 *  获取用户信息
 */
export const getCurrentUserInfo = () => {
    return store.getters.userInfo;
};

/**
 *  获取用户id
 */
export const getCurrentUserId = () => {
    const userInfo = getCurrentUserInfo();
    return userInfo && userInfo.userId && userInfo.userId.length > 0 ? userInfo.userId : "";
};

/**
 *  获取用户组织id
 */
export const getCurrentUserOrgId = () => {
    const userInfo = getCurrentUserInfo();
    return userInfo && userInfo.orgDto.orgId;
};

/**
 *  保存当前的系统类型
 */
export const setCurrentSystemType = (type, userId = getCurrentUserId()) => {
    const keysToRemove = Object.keys(localStorage).filter((key) => key.includes("_system_type"));
    // 移除相应的键值对
    keysToRemove.forEach((key) => {
        localStorage.removeItem(key);
    });
    localStorage.setItem(`${userId}_system_type`, type);
};
/**
 *  保存当前的subjectCode
 */
export const setCurrentSubjectCode = (subjectCode, userId = getCurrentUserId()) => {
    const keysToRemove = Object.keys(localStorage).filter((key) => key.includes("_subjectCode"));
    // 移除相应的键值对
    keysToRemove.forEach((key) => {
        localStorage.removeItem(key);
    });
    localStorage.setItem(`${userId}_subjectCode`, subjectCode);
};
/**
 *  保存当前的系统类型
 */
export const getCurrentSystemType = () => {
    return localStorage.getItem(`${getCurrentUserId()}_system_type`);
};
/**
 *  保存当前的系统类型
 */
export const getCurrentSubjectCode = () => {
    return localStorage.getItem(`${getCurrentUserId()}_subjectCode`);
};
