import TibiMatomo from "@tibi/tibi-matomo-plug-in";
import TrackConstants from "../../util/constants/TrackConstants.js";

export default {
    /**
     * 发送 现场学习事件
     */
    sendOffLineTrackEvent(category, action, name, value = TrackConstants.eventValues.defaultValue) {
        this.sendTrackEvent(
            category,
            action,
            name,
            value
        );
    },
    /**
     * 发送homeList的Tab事件
     */
    sendHomeTabTrackEvent(name, value = TrackConstants.eventValues.defaultValue) {
        this.sendTrackEvent(
            TrackConstants.eventCategoryNames.home,
            TrackConstants.eventActionNames.home_tab,
            name,
            value
        );
    },
    /**
     * 发送PrepareTraining事件
     */
    sendPrepareTrainingTrackEvent(name, value = TrackConstants.eventValues.defaultValue) {
        this.sendTrackEvent(
            TrackConstants.eventCategoryNames.prepare_training,
            TrackConstants.eventActionNames.prepare_training_menu,
            name,
            value
        );
    },
    /**
     * 发送SignIn事件
     */
    sendSignInTrackEvent(name, value = TrackConstants.eventValues.defaultValue) {
        this.sendTrackEvent(
            TrackConstants.eventCategoryNames.sign_in,
            TrackConstants.eventActionNames.sign_in_menu,
            name,
            value
        );
    },
    /**
     * 发送培训记录事件
     */
    sendTrainRecordTrackEvent(name, value = TrackConstants.eventValues.defaultValue) {
        this.sendTrackEvent(
            TrackConstants.eventCategoryNames.training_record,
            TrackConstants.eventActionNames.training_record_menu,
            name,
            value
        );
    },
    /**
     * 发送培训记录事件
     */
    sendTrainRecordTableTrackEvent(name, value = TrackConstants.eventValues.defaultValue) {
        this.sendTrackEvent(
            TrackConstants.eventCategoryNames.train_record_table,
            TrackConstants.eventActionNames.train_record_table_menu,
            name,
            value
        );
    },
    /**
     * 发送UploadMaterias事件
     */
    sendUploadMateriasTrackEvent(name, value = TrackConstants.eventValues.defaultValue) {
        this.sendTrackEvent(
            TrackConstants.eventCategoryNames.upload_materia,
            TrackConstants.eventActionNames.upload_materia_menu,
            name,
            value
        );
    },
    /**
     * 发送VideoTraining事件
     */
    sendVideoTrainingTrackEvent(name, value = TrackConstants.eventValues.defaultValue) {
        this.sendTrackEvent(
            TrackConstants.eventCategoryNames.video_training,
            TrackConstants.eventActionNames.video_training_menu,
            name,
            value
        );
    },
    // ===================================== base ====================================//
    /**
     * 发送统计事件
     * @param category 类别
     * @param action  行为
     * @param name  名称
     * @param value  值
     */
    sendTrackEvent(category, action, name, value) {
        let categoryStr = `offline.${category}`;
        let actionStr = `offline.${action}\``;
        let nameStr = `offline.${name}\``;
        TibiMatomo.sendTrackEvent(categoryStr, actionStr, nameStr, value);
    },
}