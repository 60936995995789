/*
 * @Author: fbz
 * @Date: 2020-08-18 18:18:51
 * @LastEditors: fbz
 * @LastEditTime: 2021-08-05 18:43:02
 */
const app = {
    state: {
        device: 'desktop',
        // 登陆的用户信息
        userInfo: {},
        // 正在培训的计划
        trainPlan: {},
        parameterCodeList: {},
        siteOrgId: '', // 站点组织id
        uploadPlatform: "", // 上传平台

    },
    mutations: {
        TOGGLE_DEVICE: (state, device) => {
            state.device = device
        },

        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo
        },
        SET_PARAMETER_CODE_LIST(state, parameterCodeList) {
            state.parameterCodeList = parameterCodeList
        },
        SET_SITE_ORG_ID(state, siteOrgId) {
            state.siteOrgId = siteOrgId
        },
        SET_UPLOAD_PLATFORM(state, data) {
            state.uploadPlatform = data
        }
    },
    actions: {
        //存储用户信息
        setUserInfo({ commit }, userInfo) {
            commit('SET_USER_INFO', userInfo)
        },
        toggleDevice: ({ commit }, device) => {
            commit('TOGGLE_DEVICE', device)
        },
        // 系统参数
        setParameterCodeList({ commit }, parameterCodeList) {
            commit('SET_PARAMETER_CODE_LIST', parameterCodeList);
        },
        setSiteOrgId({ commit }, siteOrgId) {
            commit('SET_SITE_ORG_ID', siteOrgId);
        },
        setUploadPlatform({ commit }, data) {
            commit('SET_UPLOAD_PLATFORM', data);
        }
    }
}

export default app