import DateUtil from "../util/DateUtil";

export default {
    // 计划状态
    planState(state) {
        state = parseInt(state);
        let stateStr = "";
        // 状态（0:暂停，11：无效，12：草稿，13：审核驳回，14：审核通过，20：未开始(计划开始时间大于发布日期的计划)，21：进行中,22:补学中，25：完成，30：过期未完成)
        switch (state) {
            case 0:
                stateStr = "暂停";
                break;
            case 11:
                stateStr = "无效";
                break;
            case 12:
                stateStr = "草稿";
                break;
            case 13:
                stateStr = "审核驳回";
                break;
            case 14:
                stateStr = "未开始";
                break;
            case 20:
                stateStr = "未开始";
                break;
            case 21:
                stateStr = "进行中";
                break;
            case 22:
                stateStr = "补学中";
                break;
            case 25:
                stateStr = "完成";
                break;
            case 30:
                stateStr = "过期未完成";
                break;
        }

        return stateStr;
    },

    /**
     *  计划类型
     * @param {*} addType  11实时12补录
     */
    filterPlanType(addType) {
        if (addType == 11) {
            return "实时";
        } else if (addType == 12) {
            return "补录";
        } else {
            return "";
        }
    },

    planStateColor(state) {
        state = parseInt(state);
        let stateStr = "plan-state-common";
        // 状态（0:暂停，11：无效，12：草稿，13：审核驳回，14：审核通过，20：未开始(计划开始时间大于发布日期的计划)，21：进行中,22:补学中，25：完成，30：过期未完成)
        switch (state) {
            case 0:
            case 11:
            case 12:
            case 13:
                break;
            case 14:
            case 20:
                stateStr = "plan-state-no-start";
                break;
            case 21:
            case 22:
                stateStr = "plan-state-progressing";
                break;
            case 25:
                stateStr = "plan-state-finished";
                break;
            case 30:
                stateStr = "plan-state-expired-finished";
                break;
        }

        return stateStr;
    },
    /** 签退
     ("结果（0:正常，1:异常）")
     */
    resultTypeFilter: function(val) {
        // console.log("resultTypeFilter", val);
        let signSourceStr = "";
        if (val == null || val == undefined) {
            return signSourceStr;
        }

        val = parseInt(val);

        switch (val) {
            case 0:
                signSourceStr = "正常";
                break;
            case 1:
                signSourceStr = "异常";
                break;
        }
        // console.log("resultTypeFilter signSourceStr", signSourceStr);
        return signSourceStr;
    },
    /*
        // 学习场景（10：pc网络，14：现场考试，15：pc现场学习，20：Android，21：iOS，22：公众号，23：小程序）
        sourceType: 15,
     */
    signSourceFilter: function(val) {
        // 签到来源（10：pc,20：Android，21：iOS，22：公众号，23小程序)
        let signSourceStr = "未知来源";
        if (!val) {
            return signSourceStr;
        }

        val = parseInt(val);

        switch (val) {
            case 10:
                signSourceStr = "电脑(PC)";
                break;
            case 14:
                signSourceStr = "现场考试";
                break;
            case 15:
                signSourceStr = "现场学习";
                break;
            case 20:
                signSourceStr = "安卓(APP)";
                break;
            case 21:
                signSourceStr = "苹果(APP)";
                break;
            case 22:
                signSourceStr = "公众号";
                break;
            case 23:
                signSourceStr = "小程序";
                break;
            case 30:
                signSourceStr = "电脑(H5)";
                break;
            case 31:
                signSourceStr = "安卓(H5)";
                break;
            case 32:
                signSourceStr = "苹果(H5)";
                break;
        }

        return signSourceStr;
    },

    timeFilter(time) {
        let newTime = "0秒";
        if (time) {
            newTime = DateUtil.missToMs(time);
        }
        return newTime;
    },

    timeFormatFilter(time) {
        if (!!time) {
            time = time.replace(/-/g, "/");

            let dateFtt;
            try {
                // IE兼容问题
                let dateObj = new Date(time);
                dateFtt = DateUtil.dateFtt("yyyy-MM-dd", dateObj);
            } catch (e) {
                console.error(e);
            }
            return dateFtt;
        } else {
            return "";
        }
    },

    timestampFilter(time) {
        let timeStr = "时间错误";
        if (time) {
            if (typeof time === "string") {
                time = time.replace(/-/g, "/");
            }

            timeStr = DateUtil.dateFtt("yyyy-MM-dd hh:mm:ss", new Date(time));
        }

        return timeStr;
    },
};
