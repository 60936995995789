import userUtil from "../userUtil";
import GlobalHelper from "../GlobalHelper";

export default {
    // 监听事件
    Events:{
        BeforeUnload : "beforeunload",
        Unload : "unload",
    },
    _beforeUnload_time:0,
    _gap_time:0,

    /**
     * 监听流浏览器关闭
     */
    addMonitorBrowserCloseEvent(){
        const that = this;
        window.addEventListener(this.Events.BeforeUnload,e => this.beforeUnloadFn(e));
        window.addEventListener(this.Events.Unload, e => this.unloadFn(e))
    },
    /**
     * 关闭之前事件
     */
    beforeUnloadFn(e){
        this._beforeUnload_time = new Date().getTime();
    },
    /**
     * 关闭事件
     */
    unloadFn(e){
        this._gap_time = new Date().getTime() - this._beforeUnload_time;
        if(this._gap_time <= 5){
           this.closeLogOut();
        }

    },
    /**
     * 退出登录
     */
    closeLogOut(){
        // 退出登录
        userUtil.logout().then((res) => {

        })

    },
    /**
     * 移除浏览器监听
     */
    removeBrowserCloseEvent(){
        window.removeEventListener(this.Events.BeforeUnload,e => this.beforeUnloadFn());
        window.removeEventListener(this.Events.Unload, e => this.unloadFn())
    }
}
