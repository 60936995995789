<template>
    <div class="header" :class="isOffLineMeeting ? 'meeting-header' : 'container'">
        <img :src="logoUrl" alt="" />
        <el-button @click="logout" class="logout-meeting-button" size="small" icon="el-icon-warning">
            {{ isOffLineMeeting ? "退出现场会议" : "退出现场学习" }}
        </el-button>
        <!-- <el-button class="logout" v-else type="text" @click="logout" size="small" id="step215">退出现场学习 </el-button>   -->
    </div>
</template>

<script>
import TBDriver from "@tibi/vue-driver/TBDriver.js";
import TibiMatomoManger from "../../util/manager/TibiMatomoManger";
import TrackConstants from "../../util/constants/TrackConstants";
import sys from "../../api/sys.js";
import { mapActions, mapState } from "vuex";
import Constants from "@util/constants/Constants";
import GlobalHelper from "../../util/GlobalHelper";
import { SYSTEM_TYPE } from "../../util/constants/EnumConstants";
import { getCurrentSystemType } from "../../util/user/UserManger.js";
import { getQueryStringParams } from "../../util/helper/URLHepler.js";

export default {
    data() {
        return {
            parameterCodeList: [
                Constants.parameterType.OFFLINE_STUDY_LOGO, // 顶部logo
                Constants.parameterType.ORG_SITE_CONFIG_VERSION_INFO,
                Constants.parameterType.STUDY_QRCODE_LOGO, // 二维码中间图片
                Constants.parameterType.ORG_SITE_CONFIG_WEB_TITLE_LOGIN, // 浏览器上展示logo和title
                Constants.parameterType.OFFLINE_SIGNIN_NOTICE,
                Constants.parameterType.OFFLINE_STUDY_BOTTOM_IMG, // 浏览器上展示微信公众号二维码
                Constants.parameterType.OFFLINE_MEETING_SIGN_IN_NOTICE, // 现场会议提示语
            ],
            logoUrl: "",
            type: SYSTEM_TYPE.TRAIN, // 默认培训类型
        };
    },
    watch: {
        siteOrgId: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.getAuthorityParameterList();
                }
            },
        },
    },
    computed: {
        ...mapState({
            siteOrgId: (state) => state.app.siteOrgId,
        }),
        /**
         * 是否是现场会议
         */
        isOffLineMeeting() {
            return this.type == SYSTEM_TYPE.MEETING;
        },
    },
    mounted() {
        const query = getQueryStringParams();
        !!query.type ? (this.type = query.type) : (this.type = getCurrentSystemType());
    },
    methods: {
        ...mapActions(["setParameterCodeList"]),
        logout() {
            TBDriver.resetSteps();
            this.$emit("logoutDo");
            TibiMatomoManger.sendOffLineTrackEvent(
                TrackConstants.eventCategoryNames.header,
                TrackConstants.eventActionNames.header_btn_menu,
                TrackConstants.eventNameKeys.header_btn_menu_sign_out
            );
        },
        /**
         * 刷新系统类型
         */
        refreshSystemTypeHeader(query) {
            !!query.type ? (this.type = query.type) : (this.type = getCurrentSystemType());
        },

        /**fbz 20210723
         * @description: 获取系统参数配置 header图片
         * @param {*}
         * @return {*}
         */
        getAuthorityParameterList() {
            const that = this;
            const params = {
                parameterCodeList: that.parameterCodeList,
                orgId: that.siteOrgId,
            };

            sys.getParameterCodeAdminList(params).then((response) => {
                const { success, data } = response;
                if (success) {
                    that.setParameterCodeList(data);
                    if (data) {
                        if (data[Constants.parameterType.OFFLINE_STUDY_LOGO]) {
                            const logoList = JSON.parse(data[Constants.parameterType.OFFLINE_STUDY_LOGO]);

                            for (let i = 0, len = logoList.length; i < len; i++) {
                                const item = logoList[i];
                                if (that.isOffLineMeeting) {
                                    if (item.parameterCode === "safe_meeting_system_logo_url") {
                                        that.logoUrl = item.parameterName;
                                        break;
                                    }
                                } else {
                                    if (item.parameterCode === "system_logo_url") {
                                        that.logoUrl = item.parameterName;
                                    }
                                }
                            }
                        }
                        // yxl 20210729 新增浏览器顶部图标
                        if (data[Constants.parameterType.ORG_SITE_CONFIG_WEB_TITLE_LOGIN]) {
                            const productList = JSON.parse(data[Constants.parameterType.ORG_SITE_CONFIG_WEB_TITLE_LOGIN]);
                            if (productList && productList.length > 0) {
                                const product = productList[0];
                                const iconUrl = product.parameterName;
                                GlobalHelper.modifyWindowIcon(iconUrl);
                            }
                        }
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
.meeting-header {
    padding: 0 10px;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    img {
        display: inline-block;
    }

    // 现场会议按钮
    .logout-meeting-button {
        background: #712c2c;
        border-radius: 4px;
        color: white;
        border: none;
        font-size: 16px;
    }

    .logout {
        font-size: 18px;
        color: #409eff;
        background: white;
        padding-left: 15px;
        padding-right: 15px;
        height: 35px;
        line-height: 35px;
        padding-top: 0;
    }

    .driver-fix-stacking {
        .el-button logout el-button--text driver-highlighted-element driver-position-relative {
            color: #45a0ff;
        }
    }
}
</style>
