<template>
    <el-scrollbar style="height:100%">
        <div id="app">
            <div @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
                <BrowerDown></BrowerDown>
                <el-header>
                    <Header @logoutDo="logoutDo" ref="header"></Header>
                </el-header>
                <router-view ref="offline-train-box" @loginSuccess="monitorLoginSuccess" @joinSystem="monitorJoinSystem"></router-view>
                <Footer></Footer>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
import Header from "@/views/layout/Header";
import Footer from "@/views/layout/footer/Footer.vue";
import { HudGlobal } from "./util/HudGlobal";
import BrowerDown from "./components/vue-brower-down/down";
import GlobalHelper from "./util/GlobalHelper";
import WindowListener from "./util/observers/WindowListener.js";
import { SYSTEM_TYPE } from "./util/constants/EnumConstants";
import { getCurrentSystemType } from "./util/user/UserManger";
import { MEETING } from "./util/constants/RouteConstant";
import { getQueryStringParams } from "./util/helper/URLHepler";
export default {
    name: "App",
    components: {
        Header,
        Footer,
        BrowerDown,
    },
    data() {
        return {
            type: getCurrentSystemType(),
        };
    },
    mounted() {
        // lzb 监听浏览器关闭
        WindowListener.addMonitorBrowserCloseEvent();
        const query = getQueryStringParams();
        !!query.type ? (this.type = query.type) : (this.type = getCurrentSystemType());

        document.title = this.type == SYSTEM_TYPE.MEETING ? "现场会议" : "现场学习";
    },
    destroyed() {
        WindowListener.removeBrowserCloseEvent();
    },
    methods: {
        logoutDo() {
            if (this && this.$refs["offline-train-box"] && this.$refs["offline-train-box"].logoutDo) {
                this.$refs["offline-train-box"].logoutDo();
            } else {
                const message = `是否要关闭${getCurrentSystemType() == SYSTEM_TYPE.MEETING ? "现场会议" : "现场学习"}页面`;
                HudGlobal.showAlertConfirmMessage(message).then(() => {
                    GlobalHelper.closeWindowEvent();
                });
            }
        },
        /**
         *   登录成功 lzb 2021-07-30
         * */
        monitorLoginSuccess() {
            //lzb 刷新header
            if (this.$refs.header) {
                this.$refs.header.getAuthorityParameterList();
            }
        },
        /**
         * 监听进入系统
         */
        monitorJoinSystem(item) {
            // 刷新系统类型
            item && item.type && this.$refs.header && this.$refs.header.refreshSystemTypeHeader(item);
        },

        /**
         *  监听鼠标进入
         * */
        onMouseEnter() {
            try {
                if (this.$refs["offline-train-box"]) {
                    this.$refs["offline-train-box"].monitorMouseEnter();
                }
            } catch (e) {
                // 跳过异常情况, 主要是有些页面没有时, 跳过执行
            }
        },

        /**
         *  监听鼠标离开
         * */
        onMouseLeave() {
            try {
                if (this.$refs["offline-train-box"]) {
                    this.$refs["offline-train-box"].monitorMouseLeave();
                }
            } catch (e) {
                // 跳过异常情况, 主要是有些页面没有时, 跳过执行
            }
        },
    },
};
</script>

<style>
html,
body,
#app,
.el-container {
    height: 100%;
    background: #f2f3f7;
}

#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.el-scrollbar__wrap {
    overflow-x: hidden;
}
.el-header {
    background: #409eff;
    height: 52px;
}
</style>
