import Vue from "vue";
import router from "../router";
import localforage from "localforage";
import userUtil from "../util/userUtil";
import jwt from "@tibi/json-web-token";
import { HudGlobal } from "../util/HudGlobal";
import { getCurrentSystemType } from "../util/user/UserManger";
import { SYSTEM_TYPE } from "../util/constants/EnumConstants";
import { getQueryStringParams } from "../util/helper/URLHepler";

/*
    指令使用格式
    单个v-has-role="'admin'", 里面的值需要用引号引用起来
    多个数据v-has-role="['admin','admin2']", 里面的值需要用数据装起来
 */
// 指令是否有对应的角色
function getTarget(node = document.body) {
    if (node === true) return document.body;
    return node instanceof window.Node ? node : document.querySelector(node);
}
const homes = new Map();
Vue.directive("brother-portal", {
    inserted(el, { value }) {
        const { parentNode } = el;
        const home = document.createComment("");
        let hasMovedOut = false;
        if (value) {
            parentNode.replaceChild(home, el);
            // getTarget(value).appendChild(el);
            getTarget(value).parentNode.insertBefore(el, getTarget(value));
            hasMovedOut = true;
        }
        if (!homes.has(el)) homes.set(el, { parentNode, home, hasMovedOut });
    },
    unbind(el, binding) {
        homes.delete(el);
    },
});
const homes1 = new Map();
Vue.directive("father-portal", {
    inserted(el, { value }) {
        const { parentNode } = el;
        const home = document.createComment("");
        let hasMovedOut = false;
        if (value) {
            parentNode.replaceChild(home, el);
            // getTarget(value).appendChild(el);
            getTarget(value).parentNode.insertBefore(el, getTarget(value));
            hasMovedOut = true;
        }
        if (!homes1.has(el)) homes1.set(el, { parentNode, home, hasMovedOut });
    },
    unbind(el, binding) {
        homes1.delete(el);
    },
});
Vue.directive("has-role", {
    inserted: function(el, binding) {
        let arg = binding.value;
        handleRolePermission("role_list", arg, el);
    },
});

// 指令是否有对应的权限
Vue.directive("has-permission", {
    bind: function(el, binding) {
        let arg = binding.value;
        handleRolePermission("permission_list", arg, el);
    },
});

// 指令是否有对应的身份
Vue.directive("has-indentify", {
    bind: function(el, binding) {
        let arg = binding.value;
        handleRolePermission("user_identify_list", arg, el);
    },
});

function handleRolePermission(authStr, auth, el) {
    localforage.getItem(authStr).then((dataList) => {
        let has = false;

        if (!dataList) {
            return has;
        }

        for (let i = 0; i < dataList.length; i++) {
            let data = dataList[i];

            if (auth instanceof Array) {
                for (let j = 0; j < auth.length; j++) {
                    if (data === auth[j]) {
                        has = true;
                        break;
                    }
                }
            } else {
                if (data === auth) {
                    has = true;
                    break;
                }
            }
        }
        if (!has) {
            el.remove();
        }
    });
}

userUtil.addUserData();

// 访问权限路由 重定向白名单
const whiteList = ["/", "/middle/ware"];

function closeWindow() {
    window.open("", "_self", "");
    window.close();
}

router.beforeEach((to, from, next) => {
    let type = null;
    const query = getQueryStringParams();
    !!query.type ? (type = query.type) : (type = getCurrentSystemType());
    document.title = type == SYSTEM_TYPE.MEETING ? "现场会议" : "现场学习";

    // 如果白名单里有此路由
    if (whiteList.indexOf(to.path) !== -1) {
        next();
    } else {
        localforage
            .getItem("token")
            .then((data) => {
                // 判断缓存是否失效 0未过期, 1即将过期, 2已过期
                // 如果白名单里有此路由
                if (whiteList.indexOf(to.path) !== -1) {
                    next();
                } else {
                    if (data) {
                        // 有token数据, 则直接跳转
                        // 判断缓存是否失效 0未过期, 1即将过期, 2已过期
                        let expired = jwt.isExpired(data);
                        if (expired === 2) {
                            HudGlobal.showAlertConfirmMessage("页面已失效, 请重新从管理页面进入现场学习! 是否立即关闭页面").then((res) => {
                                userUtil.removeUserData(closeWindow);
                            });
                        }

                        // 有token数据, 则直接跳转
                        next();
                    } else {
                        HudGlobal.showAlertConfirmMessage("页面已失效, 请重新从管理页面进入现场学习! 是否立即关闭页面").then((res) => {
                            userUtil.removeUserData(closeWindow);
                        });
                    }
                    // next();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
});

export default {};
