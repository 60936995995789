var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tips.down
    ? _c(
        "div",
        { staticClass: "app-brower-version-lower" },
        [
          _c("span", [
            _c("span", [_vm._v("检查到您的浏览器体验不好,")]),
            _vm.browser.name
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.browser.name) +
                      " - " +
                      _vm._s(_vm.browser.version)
                  )
                ])
              : _vm._e(),
            _c("span", [
              _vm._v(
                ", 可能导致有些功能不能正常使用, 推荐您安装360浏览器, chrome谷歌浏览器, firefox火狐浏览器!"
              )
            ]),
            _c(
              "a",
              {
                staticClass: "app-brower-version-down",
                attrs: { href: "javascript:;" },
                on: { click: _vm.downBrowser }
              },
              [_vm._v("点击即可下载")]
            ),
            _c(
              "span",
              { staticClass: "app-brower-close", on: { click: _vm.closeTips } },
              [_vm._v("关闭")]
            )
          ]),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.tips.downBox,
                width: "30%",
                title: "下载浏览器"
              },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.tips, "downBox", $event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "app-brower-box" },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.gridData } },
                    [
                      _c("el-table-column", {
                        attrs: { property: "name", label: "浏览器名称" }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "120" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.down(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("下载")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2962480953
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }