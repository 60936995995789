// import http from "../http.js"
import http from "@tibi/http";

let config = {
  // base url for http
  withCredentials: window.location.protocol === "https",
  baseUrl: process.env.VUE_APP_API_SSO_URL,
};

export default {
  /**
   *  获取用户登陆信息
   */
  offlineLogin: function(param) {
    return this.sendGetRequest("/api/offline/login", param);
  },
  /**
   *  退出登录
   */
  logout: function(param) {
    return this.sendGetRequest("/logout", param);
  },
  /**
   * @description: 发送get请求
   * @param {*} url
   * @param {*} param
   * @return {*}
   */
  sendGetRequest(url, param) {
    return new Promise((resolve, reject) => {
      http
        .once(config)
        .get(url, param)
        .then((res) => {
          this.handleRequestSuccessResponse(resolve, res);
        })
        .catch((error) => {
          this.handleRequestFailResponse(reject, error);
        });
    });
  },

  // 处置成功
  handleRequestSuccessResponse(resolve, response) {
    // 服务器断开-  rqmei 20230616
    if (response && response.hasOwnProperty("code") && response.code == 900) {
      const message =
        response.description ||
        "系统升级中，请稍后再试，恢复后可继续当前操作！";
      HudGlobal.showWarningWithMessage(message, 5000);
      return;
    }
    resolve && resolve(response);
  },

  // 处理失败
  handleRequestFailResponse(reject, error) {
    let message = "";
    // 请求取消
    if (error.hasOwnProperty("code") && error.code == 3) {
      console.warn("重复请求被取消");
    }
    // 网络离线 - lzb 20230525
    if (error.statusCode === 700 && error.code == 8100) {
      HudGlobal.showWarningWithMessage(error.description, 5000);
    }
    // 请求异常、或者响应异常 - rqmei 20230616
    if (
      error.statusCode === 700 &&
      (error.code == 8000 || error.code == 8001)
    ) {
      message = "网络连接失败";
    }
    // 请求取消
    if (error.statusCode === 700 && error.code == 3) {
      console.warn("重复请求被取消");
    }
    // 服务器断开-  rqmei 20230616
    if (error.statusCode == 900) {
      message = "系统升级中，请稍后再试，恢复后可继续当前操作！";
      HudGlobal.showWarningWithMessage(message, 5000);
    }
    reject && reject(error);
  },
};
