import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import baberrage from "./modules/baberrage";
import getters from "./getters";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        app,
        baberrage,
    },
    getters,
    plugins: [
        createPersistedState({
            key: "tb-offline-train",
            storage: window.localStorage,
        }),
    ],
});

export default store;
