
/**
 *   功能: 全局常量
 *
 *   使用方式:
 *   1. 导入文件
 *   import {Constants} from "@/utils/constants/Constants.js";
 *
 *   2. 调用方法
 *   Constants.organizationType.ORGTYPE_HEAD_OFFICE
 *
 *
 * */


const Constants = {


    pageSizes: [2, 5, 10, 15, 20, 30, 50, 100],
    pageSizeDefault: 10,
    currentPage: 1,

    fileAllowedImageTypes: ["image/jpeg", "application/x-jpg", "image/png", "image/gif", "application/x-bmp"],

    //
    // # 图片拼接地址 回显
    //
    imgSplicingConfig: {



    },
    /*
    *  常量地址配置
    * */
    ConstantsAPIConfig: {
        // 文件预览地址
        FILE_PREIVEW_API: "https://view.officeapps.live.com/op/view.aspx?src=",
        // 阿里云文件路径


    },

    /*
    *  组织类型
    *
    * */
    organizationType: {
        /*
        * 总部
        * */
        ORGTYPE_HEAD_OFFICE: "org_type_head_office",
        /*
        *  代理商
        * */
        ORGTYPE_AGENT: "org_type_agent",
        /*
        * 行业管理
        * */
        ORGTYPE_SUPPERVISE: "org_type_supervise",
        /*
        *  企业
        * */
        ORGTYPE_COMPANY: "org_type_company",
        /*
        * 机构
        * */
        ORGTYPE_ORGAN: "org_type_organ",
    },
    /*
    *
    * 组织性质
    * */
    organizationNature: {
        /*
        * 私营
        * */
        ORGNATURE_PRAVITE: "system_org_nature_private",
        /*
        * 国营
        * */
        ORGNATURE_COUNTRY: "system_org_nature_nation",
        /*
        * 股份
        * */
        ORGNATURE_SHARE: "system_org_nature_holding",
        /*
        * 合资
        * */
        ORGNATURE_JOINT: "system_org_nature_venture",
        /*
        * 外资
        * */
        ORGNATURE_FOREIGN: "system_org_nature_merchant",

    },

    /*
    *   身份类型
    *
    * */
    organizeIndentityType: {
        /*
        *  学员
        * */
        INDENTITY_STUDENT: "system_org_indentity_student",

        /*
         *  管理员
         * */
        INDENTITY_ADMIN: "system_org_indentity_admin",

        /*
        *  安全管理员
        * */
        INDENTITY_SAFE_ADMIN: "system_org_indentity_admin_safe",

        /*
         *
         *  考核人员
         * */
        INDENTITY_ASSESSORS: "system_org_indentity_assessors",

        /*
         *
         *  授课人
         * */
        INDENTITY_LECTURER: "system_org_indentity_lecturer",

    },


    /*
   *  站点管理
   *
   * */
    sizeUplodeType: {
        /*
        * 上传文件夹
        * */
        SIZE_UPDATE_NAME: "/size/templName",
        /*
        *  上传地址
        * */
        SIZE_UPDATE_URL: "org_type_agent",
    },

    /*
    *  课件分类类型
    * */
    lessonCategroryType: {
        /*
        *  通用
        * */
        LESSON_CATEGRORY_TYPE_GENERAL: "course_classification_type_general",
        /*
        *  国家法律法规
        * */
        LESSON_CATEGRORY_TYPE_REGULATIONS: "course_classification_type_regulations",
        /*
        *  单位文件
        * */
        LESSON_CATEGRORY_TYPE_FILE: "course_classification_type_unit_file",
        /*
       *  道德素质教育
       * */
        LESSON_CATEGRORY_TYPE_EDUCATION: "course_classification_type_education",
    },

    tibiOrg: {
        orgId: 110110110,
        orgName: "重庆替比网络科技有限公司"
    }

}
export { Constants }
