/*
 * @Author: fbz
 * @Date: 2020-08-18 18:18:51
 * @LastEditors: fbz
 * @LastEditTime: 2021-08-05 19:29:02
 */
import { mapState } from 'vuex';

export default {
    data() {
        return {
            nowYear: "2019",
            hostname: "safe.jsyxx.cn",
            govUrl:''
        }
    },
    computed:{
        ...mapState({
            parameterCodeList: state  => {
                return state.app.parameterCodeList
            }
        }),
        benanhao(){
            let tempBenanhao = ""
            if(this.parameterCodeList.org_site_config_version_info){
                const temp = JSON.parse(this.parameterCodeList.org_site_config_version_info)
                temp.map(item => {
                    if(item.code === "beian"){
                        tempBenanhao =item.label;
                        this.govUrl = item.content
                    }
                })
            }
            return tempBenanhao;
        }
    },
    created() {
        this.initData();
    },

    methods: {
        initData() {
            this.nowYear = new Date().getFullYear();
            this.hostname = window.location.host;
        },
        gov(){
            window.open(this.govUrl,'_blank')
        }
    }
}
