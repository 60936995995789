export default {
    /**
     * 上传的平台
     */
    UPLOAD_PLATFORM: {
        ALI: "ALI",
        HUAWEI: "HUAWEI",
        TB: "TB",
    },
};

/**
 * 系统类型
 */
export const SYSTEM_TYPE = {
    TRAIN: "train", // 现场学习
    MEETING: "meeting", // 现场会议
};
