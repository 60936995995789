import Vue from "vue";

/**
 *   功能: 全局弹框
 *
 *   使用方式:
 *   1. 导入文件import {HudGlobal} from "@/utils/HudGlobal.js";
 *   2. 调用方法  HudGlobal.showAlertMessage('测试');
 *   如果要使用点击确定之后回调 HudGlobal.showAlertMessage('测试').then(function)
 *
 *
 * */
const currentVue = null;
let messageInstance = null;
const HudGlobal = {
    /*
     *  显示 -确定 -弹框
     * */
    showAlertMessage: function(message, title = "温馨提示", fun) {
        const msg = message.length > 0 ? message : "您确定要做此操作吗!";
        this.getVue().$alert(msg, title, {
            confirmButtonText: "确定",
            callback: fun,
        });
    },
    /*
     *  显示 -确定 -弹框
     * */
    showUniversalAlert: function(message, { ...opts } = { title: "温馨提示", showClose: true }) {
        const msg = message.length > 0 ? message : "您确定要做此操作吗!";
        return this.getVue().$alert(msg, opts.title ? opts.title : "温馨提示", { ...opts });
    },
    /**
     * 显示-操作-confirm-确定弹框
     * @param message   弹框内容
     * @param cancleMsg 消失弹框信息
     *
     */
    showAlertConfirmMessage: function(message, cancleMsg) {
        return this.showAlertConfirmMessages(message, cancleMsg, "温馨提示");
    },

    /**
     * 显示-操作-confirm-确定弹框
     * @param message   弹框内容
     * @param cancleMsg 消失弹框信息
     * @param distinguishCancelAndClose 是否对 （点击取消按钮）与关闭（点击关闭按钮或遮罩层、按下 ESC 键）进行区分
     */
    showAlertConfirmMessages: function(message, cancleMsg, title, confirmButtonText, cancelButtonText, distinguishCancelAndClose = false) {
        const that = this;
        const msg = message.length > 0 ? message : "您确定要做这个操作吗?";
        if (cancleMsg == null || cancleMsg == undefined) {
            cancleMsg = "取消成功";
        } else {
            cancleMsg = cancleMsg.length > 0 ? cancleMsg : "取消成功";
        }
        if (!confirmButtonText) {
            confirmButtonText = "确定";
        }
        if (!cancelButtonText) {
            cancelButtonText = "取消";
        }
        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            that.getVue()
                .$confirm(msg, title, {
                    confirmButtonText,
                    cancelButtonText,
                    distinguishCancelAndClose,
                    type: "warning",
                    dangerouslyUseHTMLString: true,
                })
                .then(() => {
                    // 确定之后执行方法
                    if (resolve) {
                        resolve();
                    }
                })
                .catch((action) => {
                    reject(action);
                    // 取消之后
                    // that.showInfoWithMessage(cancleMsg);
                });
        });
    },
    /*
     *   显示操作成功-hud
     **/
    showSuccessWithMessage: function(message) {
        if (message == null || message == undefined) {
            message = "操作成功";
        }
        const msg = message.length > 0 ? message : "操作成功!";
        this.getVue().$message({
            type: "success",
            message: msg,
        });
    },
    /*
     *  显示操作信息
     * */
    showInfoWithMessage: function(message) {
        if (message == null || message == undefined) {
            message = "取消成功";
        }
        const msg = message.length > 0 ? message : "取消成功";
        this.getVue().$message({
            type: "info",
            message: msg,
        });
    },
    /*
     *   显示操作失败-hud
     **/
    showErrorWithMessage: function(message) {
        if (message == null || message == undefined) {
            message = "操作失败!";
        }
        const msg = message.length > 0 ? message : "操作失败!";
        this.getVue().$notify.error({
            title: "失败",
            message: msg,
        });
    },
    /*
     *   显示信息
     **/
    showMessage: function(message) {
        const msg = message.length > 0 ? message : "执行操作";
        this.getVue().$message({
            type: "info",
            message: msg,
        });
    },
    /*
     *
     *  显示警告信息
     * */
    showWarningWithMessage: function(message) {
        this.showOffsetWarningWithMessage(message, 20);
    },

    /**
     * 显示警告信息
     * @param message
     */
    showWarningWithMessage(message, duration = 3000) {
        const msg = message && message.length > 0 ? message : "取消成功";
        messageInstance && messageInstance.close();
        messageInstance = this.getVue().$message({
            type: "warning",
            message: msg,
            duration: duration,
        });
    },
    /*
     *
     *  显示警告信息
     * */
    showOffsetWarningWithMessage: function(message, offset) {
        const msg = message.length > 0 ? message : "取消成功";

        this.getVue().$message({
            type: "warning",
            offset: offset,
            message: msg,
        });
    },
    /**
     * 显示距离顶部信息100px
     */
    showOffsetTopWarningWithMessage(message) {
        this.showOffsetWarningWithMessage(message, 100);
    },

    /**
     * 获取当前vue
     * @returns {Vue | Vue}
     */
    getVue: function() {
        if (this.currentVue == null) {
            this.currentVue = new Vue();
        }
        return this.currentVue;
    },
};
export { HudGlobal };
