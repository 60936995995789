import Vue from 'vue'
import { Alert } from "element-ui";
import { Constants } from "./Constants";
import EnumConstants from './constants/EnumConstants';
import store from '../store/store';
Vue.use(Alert);
/**
 * 全局公共类
 */
// 获取URL中的参数, 返回JSON格式
Vue.prototype.getUrlParams = function (url) {
    try {
        url = url.match(/\?([^#]+)/)[1];
        let obj = {}, arr = url.split('&');
        for (let i = 0; i < arr.length; i++) {
            let subArr = arr[i].split('=');
            obj[subArr[0]] = subArr[1];
        }
        return obj;

    } catch (err) {
        return null;
    }
}


// 返回常量命名
Vue.prototype.Constants = Constants;

Vue.prototype.$alert = Alert;


window.$TBUpload = {};
/**
 * @description: 上传平台
 * @return {*}
 */
window.$TBUpload.uploadPlatform = store.getters.uploadPlatform || EnumConstants.UPLOAD_PLATFORM.HUAWEI;
/**
 * @description:  替比云上传平台
 * @return {*}
 */
window.$TBUpload.uploadSubPlatform = EnumConstants.UPLOAD_PLATFORM.HUAWEI;