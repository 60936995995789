<template>
    <div class="app-brower-version-lower" v-if="tips.down">
        <span>
            <span>检查到您的浏览器体验不好,</span>
            <span v-if="browser.name">{{ browser.name }} - {{ browser.version }}</span>
            <span>, 可能导致有些功能不能正常使用, 推荐您安装360浏览器, chrome谷歌浏览器, firefox火狐浏览器!</span>
            <a class="app-brower-version-down" href="javascript:;" @click="downBrowser">点击即可下载</a>
            <span class="app-brower-close" @click="closeTips">关闭</span>
        </span>

        <el-dialog :visible.sync="tips.downBox" width="30%" title="下载浏览器">
            <div class="app-brower-box" >
                <el-table :data="gridData">
                    <el-table-column property="name" label="浏览器名称" ></el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="down(scope.row)" size="small">下载</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Bowser from "bowser";

    export default {
        props: {},
        data() {
            return {
                tips: {
                    down: false,
                    downBox: false
                },
                browser: {
                    name: "",
                    version: ""
                },
                gridData: [
                    {
                        name: '360浏览器[推荐]',
                        url: 'http://down.360safe.com/se/360se10.0.2004.0.exe',
                    }, {
                        name: 'chrome谷歌浏览器[推荐]',
                        url: 'https://dl.google.com/tag/s/appguid%3D%7B8A69D345-D564-463C-AFF1-A69D9E530F96%7D%26iid%3D%7B566DF992-1195-6E28-C689-0B5E3C2F3719%7D%26lang%3Dzh-CN%26browser%3D4%26usagestats%3D1%26appname%3DGoogle%2520Chrome%26needsadmin%3Dprefers%26ap%3Dx64-stable-statsdef_1%26installdataindex%3Dempty/update2/installers/ChromeSetup.exe',
                    }, {
                        name: 'firefox火狐浏览器',
                        url: 'https://download-ssl.firefox.com.cn/releases-sha2/stub/official/zh-CN/Firefox-latest.exe',
                    }],
            }
        },
        created() {

        },
        mounted() {
            const browser = Bowser.getParser(window.navigator.userAgent);
            let result = browser.getResult();
            if (result) {
                let browerObj = result.browser;
                this.browser.name = browerObj.name;
                this.browser.version = browerObj.version;

                if (this.browser.name.toLocaleLowerCase().indexOf("ie") !== -1) {
                    this.tips.down = true;
                }
            }
        },
        methods: {
            closeTips() {
                this.tips.down = false;
            },
            downBrowser() {
                this.tips.downBox = true;
            },
            down(row) {
                let url = row.url;
                window.open(url, "_blank").location;
            }
        }
    }
</script>
<style lang="scss">
    .app-brower-version-lower {
        z-index: 9999;
        display: block;
        width: 100%;
        height: 40px;
        position: fixed;
        top: 0;
        background-color: #f60;
        line-height: 40px;
        text-align: center;
        font-weight: bold;
        color: #4e4e4e;
    }

    .app-brower-version-down {

    }

    .app-brower-close {
        display: inline-block;
        position: absolute;
        right: 5%;
        color: #4e4e4e;
        cursor: pointer;
    }

    .app-brower-close:hover {
        color: red;
        font-weight: bolder;
    }
</style>