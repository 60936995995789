export default {
    /**
     *  存放数据到storemanger
     *
     *  key: 存放key  string
     *  value: 存放的值   可以是string 也可以是json
     */
    saveDataToLocalStorage: function (key, value) {

        if (key == null || key.length == 0) return;
        if (value == null) return;

        if (typeof value !== 'string') {
            value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
    },

    /**
     * 从storemanger取出数据 - 字符串
     *
     *  key: 存放key  string
     */
    getDataWithLocalStorage: function (key) {
        if (key == null || key.length == 0) return;
        return localStorage.getItem(key);

    },
    /**
     * 从storemanger取出数据  - Object
     * @param key
     * @returns {string}
     */
    getObjectWithLocalStorage: function (key) {
        if (key == null || key.length == 0) return null;
        const str = this.getDataWithLocalStorage(key);
        if (str != null && str.length != 0) {
            const item = JSON.parse(str);
            return item;
        } else {
            return null;
        }

    },
    /**
     * 获取所有的key
     */
    getStorageAllKeys() {
        // 移除所有
        const keyList = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            keyList.push(key);
        }
        return keyList;

    },

    /**
     *  移除key
     */
    removeDataWithLocalStorage: function (key) {
        if (key == null || key.length === 0) return;

        localStorage.removeItem(key);
    },

    /**
     * 移除所有数据
     */
    removeAllDataWithLocalStorage() {
        // 移除所有
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            this.removeDataWithLocalStorage(key);
        }
        localStorage.clear();
    },
}
