/*
 * @Author: fbz
 * @Date: 2020-08-18 18:18:51
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-12-12 17:54:41
 */
/**
 * 全局处理方法
 */
export default {
    /**
     * 打开新的网页
     */
    openWithUrl(url) {
        if (url == null || url.length == 0) {
            return;
        }
        window.open(url, "_blank").location;
    },
    /**
     * 关闭窗口
     */
    closeWindowEvent() {
        let userAgent = navigator.userAgent;
        if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
            window.location.href = "about:blank";
            window.opener = null;
            window.open(" ", "_self").location;
            window.close();
        } else {
            window.opener = null;
            window.open(" ", "_self").location;
            window.close();
        }
    },
    /**fbz 2021-0728
     * @description: url转base64
     * @param {*} url
     * @param {*} callback
     * @return {*}
     */
    getBase64Image(url, callback) {
        var Img = new Image(),
            dataURL = "";
        Img.src = url + "?v=" + Math.random();
        Img.setAttribute("crossOrigin", "Anonymous");
        Img.onload = function() {
            var canvas = document.createElement("canvas"),
                width = Img.width,
                height = Img.height;
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(Img, 0, 0, width, height);
            dataURL = canvas.toDataURL("image/jpeg");
            return callback ? callback(dataURL) : null;
        };
    },
    /**
     * 修改网站的图标 lzb 2021-07-29
     */
    modifyWindowIcon(iconUrl) {
        if (iconUrl && iconUrl.length > 0) {
            const link = document.querySelector("link[rel*='icon']");
            link.href = iconUrl; // icon图标
        }
    },
};
