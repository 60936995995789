/*
 * @Author: fbz
 * @Date: 2021-07-23 17:31:11
 * @LastEditors: fbz
 * @LastEditTime: 2021-07-23 17:32:16
 */
import http from "../util/http/studentHttp";
const baseUrl = process.env.VUE_APP_API_BASE_URL;

export default {
    /**
     * @description: 获取管理端参数列表
     * @param {*} query---List集合
     * @return {*}
     */    
    getParameterCodeAdminList(query){
        return http.get(baseUrl + '/api/system/parameter/org/list', query);
    }
}