import router from "../../../router.js";
import { SYSTEM_TYPE } from "../../../util/constants/EnumConstants.js";
import { MEETING, TRAIN } from "../../../util/constants/RouteConstant.js";
import { getQueryStringParams } from "../../../util/helper/URLHepler.js";
import { getCurrentSubjectCode, getCurrentSystemType } from "../../../util/user/UserManger.js";

/**
 * 跳转到培训列表
 */
export const gotoTrainList = ({ ...obj }) => {
    router.push({
        path: TRAIN.TRAIN_LIST,
        query: {
            type: SYSTEM_TYPE.TRAIN,
            ...obj,
        },
    });
};
/**
 * 跳转回首页
 */
export const gotoHomeList = ({ ...obj }) => {
    const query = getQueryStringParams();
    const type = query.type ? query.type : getCurrentSystemType();

    if (type === SYSTEM_TYPE.MEETING) {
        router.push({
            path: MEETING.MEETING_LIST,
            query: {
                type: SYSTEM_TYPE.MEETING,
                subjectCode: getCurrentSubjectCode(),
                ...obj,
            },
        });
    } else {
        router.push({
            path: TRAIN.TRAIN_LIST,
            query: {
                type: SYSTEM_TYPE.TRAIN,
                ...obj,
            },
        });
    }
};
