var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "timer" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "w-timer-alert-dialog",
            on: {
              click: function($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.onCloseAlert.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "custom-alert-content" }, [
              _c("div", { staticClass: "c-title" }, [_vm._v("温馨提示")]),
              _c("div", { staticClass: "c-message" }, [
                _c("i", { staticClass: "el-icon-warning" }),
                _c("span", [_vm._v(_vm._s(_vm.options.message))])
              ]),
              _c(
                "div",
                { staticClass: "c-buttons" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.onCloseAlert }
                    },
                    [_vm._v(_vm._s(_vm.getCancelButtonText))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.onConfirmAlert }
                    },
                    [_vm._v(_vm._s(_vm.options.confirmButtonText))]
                  )
                ],
                1
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }