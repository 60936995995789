import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import { COMMON, MEETING, TRAIN } from "./util/constants/RouteConstant.js";

let routeMode = process.env.VUE_APP_ROUTE_MODE;
export default new Router({
    // history hash
    mode: routeMode || "hash",
    routes: [
        {
            path: TRAIN.TRAIN_LIST,
            name: "HomeList",
            component: () => import("@/views/home-list/HomeList.vue"),
        },
        {
            path: "/",
            name: "Index",
            component: () => import("@/views/middle-ware/Index.vue"),
        },
        {
            path: "/middle/ware",
            name: "Index",
            component: () => import("@/views/middle-ware/Index.vue"),
        },
        {
            path: MEETING.MEETING_LIST,
            name: "MeetingList",
            component: () => import("@/views/meeting/meeting-list/MeetingList.vue"),
        },
        {
            path: MEETING.MEETING_PREPARE,
            name: "MeetingPrepare",
            component: () => import("@/views/meeting/meeting-prepare/MeetingPrepare.vue"),
        },
        {
            path: "/uploadMaterials",
            name: "UploadMaterias",
            component: () => import("@/views/upload-materias/UploadMaterias.vue"),
        },
        {
            path: TRAIN.TRAIN_PREPARE,
            name: "PrepareTraining",
            component: () => import("@/views/prepare-training/PrepareTraining.vue"),
        },
        {
            path: COMMON.VERIFY_PHOTO,
            name: "SignIn",
            component: () => import("@/views/sign-in/SignIn.vue"),
        },
        {
            path: COMMON.TRAIN_VIDEO_STUDY,
            name: "VideoTraining",
            component: () => import("@/views/video-training/VideoTraining.vue"),
        },
        {
            path: COMMON.TRAIN_RECORD_LIST,
            name: "TrainingRecords",
            component: () => import("@/views/training-records/TrainingRecords.vue"),
        },
        {
            path: "/404",
            name: "404",
            component: () => import("@/views/error/404.vue"),
        },
        { path: "*", redirect: "/404", hidden: true },
    ],
});
