var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header",
      class: _vm.isOffLineMeeting ? "meeting-header" : "container"
    },
    [
      _c("img", { attrs: { src: _vm.logoUrl, alt: "" } }),
      _c(
        "el-button",
        {
          staticClass: "logout-meeting-button",
          attrs: { size: "small", icon: "el-icon-warning" },
          on: { click: _vm.logout }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.isOffLineMeeting ? "退出现场会议" : "退出现场学习") +
              " "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }