/*
 * 获取浏览器地址栏里的URL参数, 注:不支持中文参数, 需要2边手动转decodeURI
 * */
export const getURLQueryString = (name) => {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let regVal = window.location.search.substr(1).match(reg);
    if (regVal != null) {
        return unescape(regVal[2]);
    }
    return null;
};

/**
 *  获取浏览器参数对象
 */
export const getQueryStringParams = () => {
    const params = {};
    var queryString = window.location.search.substring(1);

    if (queryString) {
        var paramPairs = queryString.split("&");

        for (var i = 0; i < paramPairs.length; i++) {
            var pair = paramPairs[i].split("=");
            var key = decodeURIComponent(pair[0]);
            var value = decodeURIComponent(pair[1] || "");

            params[key] = value;
        }
    }
    return params;
};
