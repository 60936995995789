/*
 * @Author: fbz
 * @Date: 2020-08-18 18:18:51
 * @LastEditors: fbz
 * @LastEditTime: 2021-07-28 09:11:45
 */
const getters = {
    device: state => state.app.device,
    roles: state => state.app.userInfo.roles,
    userName: state => state.app.userInfo.userName,
    parameterCodeList: state => state.app.parameterCodeList,
    orgId: state => state.app.userInfo.orgId,
    orgName: state => state.app.userInfo.orgName,
    userId: state => state.app.userInfo.userId,
    realName: state => state.app.userInfo.realName,
    roleInfoArr: state => state.app.userInfo.orgUserRole,
    roleIdArr: state => state.app.userInfo.orgUserRole.map(data => data.roleId),
    userInfo: state => state.app.userInfo,
    barrageType: state => state.baberrage.barrageType,
    errorType: state => state.baberrage.errorType,
    transparency: state => state.baberrage.transparency,
    fontSize: state => state.baberrage.fontSize,
    baberrageRows: state => state.baberrage.baberrageRows,
    barrageIsShow: state => state.baberrage.barrageIsShow,
    uploadPlatform: state => state.app.uploadPlatform,
}
export default getters