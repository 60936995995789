import "@babel/polyfill";
import Es6Promise from "es6-promise";
Es6Promise.polyfill();

import Vue from "vue";
import Vuex from "vuex";
import store from "./store/store";
import filters from "./filters/filters";

import App from "./App.vue";
import { vueBaberrage } from "vue-play-baberrage";

import DomPortal from "vue-dom-portal";

import directive from "./directives/permission";
import router from "./router";
import Base from "./assets/js/base.js";
import localforage from "localforage";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./util/global.js";

import "./assets/scss/index.scss";

// 埋点
import TibiMatomo from "@tibi/tibi-matomo-plug-in";

TibiMatomo.init({ siteId: "3", host: process.env.VUE_APP_API_USER_ACTION_URL });

// 新手引导
import VueDriver from "@tibi/vue-driver";
Vue.use(VueDriver);
Vue.use(DomPortal);
Vue.use(vueBaberrage);

Vue.use(ElementUI);
Vue.use(Vuex);
Base.debug();
// 挂载
Vue.prototype.$base = Base;
Vue.config.productionTip = false;

Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]));

new Vue({
    router,
    directive,
    store,
    render: (h) => h(App),
}).$mount("#app");
