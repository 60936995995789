var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "footer-container" }, [
    _c("div", { staticClass: "footer-box" }, [
      _c("div", { on: { click: _vm.gov } }, [_vm._v(_vm._s(_vm.benanhao))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }