import Constants from "@util/constants/Constants";
import TibiMatomoManger from "../../util/manager/TibiMatomoManger";
import TrackConstants from "../../util/constants/TrackConstants";

const checkList = [Constants.barrageType.SignIn, Constants.barrageType.SpotCheck, Constants.barrageType.SignOut]; // 内容项
const checkErrorList = [Constants.barrageType.Stay, Constants.barrageType.Error]; // 内容项状态

const baberrage = {
    state: {
        barrageType: checkList, // 弹幕类容项
        errorType: checkErrorList, // 抽验异常弹幕
        transparency: 1, //弹幕透明度
        fontSize: 1, // 弹幕字体
        baberrageRows: 3, // 弹幕行数
        barrageIsShow: true, // 弹幕是否显示
    },
    mutations: {
        SET_BARRAGE_TYPE(state, barrageType) {
            state.barrageType = barrageType;
        },
        SET_ERROR_TYPE(state, errorType) {
            state.errorType = errorType;
        },
        SET_TRANSPARENCY(state, transparency) {
            state.transparency = transparency;
        },
        SET_FONT_SIZE(state, fontSize) {
            state.fontSize = fontSize;
        },
        SET_BABERRAGE_ROWS(state, baberrageRows) {
            state.baberrageRows = baberrageRows;
        },
        SET_BARRAGE_IS_SHOW(state, barrageIsShow) {
            state.barrageIsShow = barrageIsShow;
        },
    },
    actions: {
        setBarrageType({ commit }, barrageType) {
            commit("SET_BARRAGE_TYPE", barrageType);
        },
        setErrorType({ commit }, errorType) {
            commit("SET_ERROR_TYPE", errorType);
        },
        setTransparency({ commit }, transparency) {
            commit("SET_TRANSPARENCY", transparency);
        },
        setFontSize({ commit }, fontSize) {
            commit("SET_FONT_SIZE", fontSize);
        },
        setBaberrageRows({ commit }, baberrageRows) {
            commit("SET_BABERRAGE_ROWS", baberrageRows);
        },
        setBarrageIsShow({ commit }, barrageIsShow) {
            commit("SET_BARRAGE_IS_SHOW", barrageIsShow);
        },
    },
};

export default baberrage;
