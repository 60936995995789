const Constants = {
    pageSizes: [2, 5, 10, 15, 20, 30, 50, 100],
    pageSizeDefault: 10,
    currentPage: 1,

    /**
     *   身份类型
     * */
    organizeIdentityType: {
        /**
         *  学员
         * */
        IDENTITY_STUDENT: "system_org_indentity_student",

        /**
         *  管理员
         * */
        IDENTITY_ADMIN: "system_org_indentity_admin",

        /**
         *  安全管理员
         * */
        IDENTITY_SAFE_ADMIN: "system_org_indentity_admin_safe",

        /**
         *  考核人员
         * */
        IDENTITY_ASSESSORS: "system_org_indentity_assessors",

        /**
         *
         *  授课人
         * */
        IDENTITY_LECTURER: "system_org_indentity_lecturer",
    },
    /**
     * 弹幕类型
     * 弹幕类型，10：签到，20：抽验(正常：待抽验/抽验成功，异常：连续多次未抽验，系统签退/比对失败),21 待抽验(算正常情况)，
     * 22：过期系统签退（异常情况）,30：签退，100：自定义
     * 内容项状态（0:异常，1：正常)
     */
    barrageType: {
        SignIn: 10, // 签到
        SpotCheck: 20, // 抽验
        SpotCheckError: 22, // 多次未抽验
        SpotCheckStay: 21, // 待抽验
        SignOut: 30, // 签退
        Custom: 100, // 自定义
        Stay: 1, // 正常
        Error: 0, // 异常
    },

    /**
     * 获取基础参数配置
     * */
    parameterType: {
        OFFLINE_STUDY_LOGO: "offline_study_logo", // 顶部logo
        ORG_SITE_CONFIG_VERSION_INFO: "org_site_config_version_info",
        STUDY_QRCODE_LOGO: "study_qrcode_logo", // 二维码中间图片
        ORG_SITE_CONFIG_WEB_TITLE_LOGIN: "org_site_config_web_title_logo", // 浏览器上展示logo和title
        OFFLINE_STUDY_BOTTOM_IMG: "offline_study_bottom_img", // 浏览器上展示微信公众号二维码
        OFFLINE_SIGNIN_NOTICE: "offline_signin_notice", //现场学习签到 温馨提示

        OFFLINE_MEETING_SIGN_IN_NOTICE: "safe_meeting_offline_signin_notice", // 现场会议签到通知
    },
};

export default Constants;
