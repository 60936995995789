/**************************************时间格式化处理************************************/
export default {
    format: {
        YYYY_MM_DD_HH_MM_SS: "yyyyMMddhhmmss",
        YYYY_MM_DD: "yyyyMMdd",
        HH_MM_SS: "hhmmss",
    },
    dateFtt: (fmt, date) => {
        var o = {
            "M+": date.getMonth() + 1,                 //月份
            "d+": date.getDate(),                    //日
            "h+": date.getHours(),                   //小时
            "m+": date.getMinutes(),                 //分
            "s+": date.getSeconds(),                 //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds()             //毫秒
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    },
    /**
     * 日期转时间戳
     * @param strtime
     * @returns {number}
     * @constructor
     */
    YMDToDate(strtime) {
        let date = new Date(strtime);
        let time = Date.parse(date) / 1000;
        return time;
    },
    /**
     * 将分钟转为HH：mm
     * @param minute
     */
    minuteToHHmm(minute) {
        if (minute === '' || minute === null || minute === undefined) {
            return '';
        }
        // console.log(minute%60);
        // console.log(minute/60);
        let h = parseInt(minute / 60);
        let hh = h > 9 ? h : "0" + h;
        let m = minute % 60;
        let mm = m > 9 ? m : "0" + m;
        return hh + ":" + mm;
    },
    /** -- 未使用
     * 将字符串hh:mm格式的实际转为分钟
     * @param hhmm
     * @returns {number}
     */
    hhmmToMinute(hhmm) {
        if (hhmm === '' || hhmm === null || hhmm === undefined) {
            return 0;
        }
        let time = hhmm.split(":");
        return parseInt(time[0]) * 60 + parseInt(time[1]);
    },
    /**
     * 获取某日期days天后的日期
     * @param startDate
     * @param days
     */
    addDays(startDateString, days) {
        let time = this.DateAdd('d', days, new Date(startDateString));
        return time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate();
    },
    /**
     * 增加某个月
     * @param startDate
     * @param months
     */
    addMonths(startDateString, months) {
        let time = this.DateAdd('m', months, new Date(startDateString));
        return time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate();
    },
    /**
     * 当前日期
     * @returns {string}
     */
    getNowFormatDate() {
        let time = new Date();
        return time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate();
    },
    /**
     * 获取当月最后一天
     * @param dateString
     */
    getMonthLastDay(dateString) {
        let date1 = new Date(dateString);
        let time = new Date(date1.getFullYear(), date1.getMonth() + 1, 0);
        return time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate();
    },
    /**
     * 当前月的上一个月
     */
    getLastMonth() {
        let date = new Date();
        date.setMonth(date.getMonth() - 1);
        const dateString = date.getFullYear() + "-" + (date.getMonth() + 1);
        return dateString;

    },
    /**
     * 获取季度最后一天
     * @param dateString
     */
    getQuarterLastDay(dateString) {
        let date1 = new Date(dateString);
        let month = date1.getMonth() + 1;
        let year = date1.getFullYear();
        if (month <= 3) {
            return year + "-03-31"
        } else if (month <= 6) {
            return year + "-06-30"
        } else if (month <= 9) {
            return year + "-09-30"
        } else {
            return year + "-12-31"
        }
    },
    /**
     * 获取当年最后一天
     * @param dateString
     */
    getYearLastDay(dateString) {
        let date1 = new Date(dateString);
        let year = date1.getFullYear();
        return year + "-12-31"
    },
    /**
     * 获取当月第一天到最后一天
     */
    getCurrentMonths() {
        let now = new Date();//当前时间
        let year = now.getFullYear();//当前年份
        let month = now.getMonth() + 1;//js获取到的是月份是 0-11 所以要加1
        let nextMonthFirstDay = new Date([year, month + 1, 1].join('-')).getTime();//下月第一天的时间戳
        let oneDay = 1000 * 24 * 60 * 60;
        let monthLast = new Date(nextMonthFirstDay - oneDay).getDate();//当月的最后一天
        let beg = year + '-' + month + '-01'    //当月第一天
        let end = year + '-' + month + '-' + monthLast  //当月最后一天
        return [beg, end];
    },
    /**
     *
     * @param interval 单位 y 年 q 季度 m 月 w周 d 天....
     * @param number 值
     * @param date 初始时间
     * @returns {*}
     * @constructor
     */
    DateAdd(interval, number, date) {
        switch (interval) {
            case "y": {
                date.setFullYear(date.getFullYear() + number);
                return date;
                break;
            }
            case "q ": {
                date.setMonth(date.getMonth() + number * 3);
                return date;
                break;
            }
            case "m": {
                date.setMonth(date.getMonth() + number);
                return date;
                break;
            }
            case "w": {
                date.setDate(date.getDate() + number * 7);
                return date;
                break;
            }
            case "d": {
                date.setDate(date.getDate() + number);
                return date;
                break;
            }
            case "h": {
                date.setHours(date.getHours() + number);
                return date;
                break;
            }
            case "m": {
                date.setMinutes(date.getMinutes() + number);
                return date;
                break;
            }
            case "s": {
                date.setSeconds(date.getSeconds() + number);
                return date;
                break;
            }
            default: {
                date.setDate(date.getDate() + number);
                return date;
                break;
            }
        }
    },


    getNowInDateBetwen(a, b, x, y) {
        var times1 = [], times2 = [];
        if (a < b) {
            //未跨天
            times1.push([a, b]);
        } else {
            //跨天
            times1.push([a, "24:00"], ["00:00", b]);
        }

        if (x < y) {
            times2.push([x, y]);
        } else {
            times2.push([x, "24:00"], ["00:00", y]);
        }

        var flag = false;
        //循环比较时间段是否冲突
        for (var i = 0; i < times1.length; i++) {
            if (flag) break;
            for (var j = 0; j < times2.length; j++) {
                if (check(times1[i][0], times1[i][1], times2[j][0], times2[j][1])) {
                    flag = true;
                    break;
                }
            }
        }
        if (flag) {
            // alert("发生冲突");
            return false;
        } else {
            // alert("没有冲突");
            return true;
        }

        function check(a, b, x, y) {
            if (y < a || b < x) {
                return false;
            } else {
                return true;
            }
        }
    },

    /*
    *  获取当前日期的字符串yyyyMMdd
    * */
    getCurrentDatePathStr() {
        const str = this.dateFtt('yyyyMMdd', new Date());
        return str;
    },


    /*未使用
    *  处理时间
    * */
    formatterTimeShowStr(val) {
        let time = this.YMDToDate(val) * 1000;
        const soureDate = new Date(val);
        if (time) {
            let newData = Date.parse(new Date());
            let diffTime = Math.abs(newData - time);

            if (diffTime >= 365 * 24 * 3600 * 1000) {
                //去年以及去年之前：显示年月日
                let dateT = new Date(time);
                let y = dateT.getFullYear();
                let m = dateT.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                let d = dateT.getDate();
                d = d < 10 ? ('0' + d) : d;
                return y + '-' + m + '-' + d;
            } else if (diffTime > 2 * 24 * 3600 * 1000) {
                // //注释(今年以内到昨天之前：显示年月);
                let dateT = new Date(time);
                let y = dateT.getFullYear();
                let m = dateT.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                return y + "-" + m;
            } else if (diffTime > 24 * 3600 * 1000 && diffTime < 2 * 24 * 3600 * 1000) {
                //昨天：显示昨天 时分
                let dateT = new Date(time);
                let h = dateT.getHours();
                h = h < 10 ? ('0' + h) : h;
                let minute = dateT.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                const padding = (new Date()).getDay() - soureDate.getDay();
                if (padding > 0) {
                    return '昨天（' + h + ':' + minute + '）';
                } else {
                    return '今天（' + h + ':' + minute + '）';
                }

            } else if (diffTime < 24 * 3600 * 1000 && diffTime > 5 * 3600 * 1000) {
                // 今天5个小时 - 24小时：显示今天 时分
                let dateTime = new Date(time);
                let h = dateTime.getHours();
                h = h < 10 ? ('0' + h) : h;
                let minute = dateTime.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;

                const padding = (new Date()).getDay() - soureDate.getDay();
                if (padding > 0) {
                    return '昨天（' + h + ':' + minute + '）';
                } else {
                    return '今天（' + h + ':' + minute + '）';
                }
            } else if (diffTime < 5 * 3600 * 1000 && diffTime > 3600 * 1000) {
                // 5个小时内1小时之前：显示几个小时前
                let dayNum = Math.floor(diffTime / (60 * 60 * 1000));
                return dayNum + "小时前";
            } else if (diffTime < 3600 * 1000 && diffTime > 5 * 60 * 1000) {
                //1小时内5分钟之前：显示几分钟前
                let minuteNum = Math.floor(diffTime / (60 * 1000));
                return minuteNum + "分钟前";
            } else if (diffTime < 5 * 60 * 1000 && diffTime > 0) {
                // 5分钟以内：显示刚刚
                return "刚刚";
            }
        } else {
            return "";
        }
    },

    /**
     * 返回值有两种！

     1、负值 代表过去了多少天

     2、正值 代表距离设定的时间还有多少天
     * @param yourDate
     * @returns {number}
     */
    getBeforeDate(yourDate) {
        let now = new Date();
        let aftertime = new Date(yourDate);
        let year = now.getFullYear();
        let mon = now.getMonth() + 1;
        let day = now.getDate();
        let year_after = aftertime.getFullYear();
        let mon_after = aftertime.getMonth() + 1;
        let day_after = aftertime.getDate();
        let chs = 0;

        //获取当月的天数
        function DayNumOfMonth(Year, Month) {
            return 32 - new Date(Year, Month - 1, 32).getDate();
        }

        if (aftertime.getTime() - now.getTime() < 0) {
            let temp1 = day_after;
            let temp2 = mon_after;
            let temp3 = year_after;
            day_after = day;
            mon_after = mon;
            year_after = year;
            day = temp1;
            mon = temp2;
            year = temp3;
        }
        if (year == year_after) {
            //不跨年
            if (mon == mon_after) {
                //不跨年不跨月
                chs += day_after - day;
            } else {
                //不跨年跨月
                chs += DayNumOfMonth(year, mon) - day + 1;//加上第一个不满的
                for (let i = 1; i < mon_after - mon; i++) {
                    chs += DayNumOfMonth(year, mon + i);
                }
                chs += day_after - 1;//加上
            }
        } else {
            //存在跨年
            chs += DayNumOfMonth(year, mon) - day + 1;//加上开始年份不满的一个月
            for (let m = 1; m < 12 - mon; m++) {
                chs += DayNumOfMonth(year, mon + m);
            }
            for (let j = 1; j < year_after - year; j++) {
                if ((year + j) % 400 == 0 || (year + j) % 4 == 0 && (year + j) % 100 != 0) {
                    chs += 366;
                } else {
                    chs += 365;
                }
            }
            for (let n = 1; n <= mon_after; n++) {
                chs += DayNumOfMonth(year_after, n);
            }
            chs += day_after - 1;
        }
        if (aftertime.getTime() - now.getTime() < 0) {
            return -chs;
        } else {
            return chs;
        }
    },

    /**
     * 秒 转 分+秒
     */
    missToMs(s){
        //计算分钟
        //算法：将秒数除以60，然后下舍入，既得到分钟数
        let h;
        let str = "";
        h  =   Math.floor(s/60);
        //计算秒
        //算法：取得秒%60的余数，既得到秒数
        s  =   s%60;
        //将变量转换为字符串
        h    +=    '';
        s    +=    '';
        if(h != 0){
            str = h+'分';
            if(s == 0){
                str = str + '钟'
            }
        }
        if(s != 0){
            str = str + s+'秒';
        }
        if(str.length == 0){
            str = "0秒";
        }
        return str;
    },
    // --未使用
    minuteToMs(m){
        let s;
        s=m*60;
        //计算分钟
        //算法：将秒数除以60，然后下舍入，既得到分钟数
        let h;
        h  =   Math.floor(s/60);
        //计算秒
        //算法：取得秒%60的余数，既得到秒数
        s  =   Math.round(s%60);
        //将变量转换为字符串
        h    +=    '';
        s    +=    '';
        // //如果只有一位数，前面增加一个0
        // h  =   (h.length===1)?'0'+h:h;
        // s  =   (s.length===1)?'0'+s:s;
        return h+'分'+s+'秒';
    },
    /**
     * 日期字符串 --> 日期没有时分秒  e.g 2018-10-08 00:00:00  --> 2018-10-08
     */
    formatterDateYMDStr(str){
        if(str == null || str.length == 0){
            return "";
        }
        if(str.length < 10){
            return "";
        }
        const result = str.substr(0,10);
        return result;
    },

    /**未使用
     * 秒-->分
     */
    formatterSecondToMinite(second){
        if(second == null || second == 0){
            return 0;
        }
        const min = second / 60;
        const m = Math.floor(min);
        return m;
    }


};

